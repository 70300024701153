import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

export const WebinarContext = React.createContext({
  title: '',
})

interface PageTemplateProps {
  data: {
    page: {
      path?: string
      title?: string
      seo: any
      flexContent: any
    }
  }
  location?: any
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { title = '', flexContent, seo },
  },
  location = {},
}) => (
  <Layout location={location}>
    <SEO seo={seo} />

    <WebinarContext.Provider
      value={{
        title,
      }}
    >

    <FlexContentHandler
      prefix="Webinar_Flexcontent"
      fields={flexContent}
      location={location}
      title={title}
    />
    </WebinarContext.Provider>
  </Layout>
)

export const pageQuery = graphql`
  query WebinarById($id: String!) {
    page: wpWebinar(id: { eq: $id }) {
      ...generalWebinarPostFragment
    }
  }
`

export default PageTemplate
